/* eslint-disable no-restricted-syntax */
/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { v4 as uuidV4 } from 'uuid';
import { Mops } from '../types';
import { getBoundingBox, inRange } from '../utils';

export const toBounds = ({
  top,
  right,
  bottom,
  left,
}: Pick<ClientRect, 'top' | 'right' | 'bottom' | 'left'>) => (
  {
    position,
    size,
  }: Mops.BoundingBox,
  {},
  model = position,
) => {
  const boundaries = {
    bottom: bottom - size.height / 2,
    left: left + size.width / 2,
    right: right - size.width / 2,
    top: top + size.height / 2,
  };
  const snap: Mops.PositionModel = {
    x: Math.max(boundaries.left, Math.min(boundaries.right, model.x)),
    y: Math.max(boundaries.top, Math.min(boundaries.bottom, model.y)),
  };
  return snap;
};

export const toGrid = ({
  x = 1,
  y = 1,
}) => ({ position, size }: Mops.BoundingBox, {}, model = position) => {
  const half = {
    x: size.width / 2,
    y: size.height / 2,
  };
  const snap = {
    bottom: Math.round((model.y + half.y) / y) * y - half.y,
    left: Math.round((model.x - half.x) / x) * x + half.x,
    right: Math.round((model.x + half.x) / x) * x - half.x,
    top: Math.round((model.y - half.y) / y) * y + half.y,
  };
  const diff = {
    bottom: Math.abs(model.y - snap.bottom),
    left: Math.abs(model.x - snap.left),
    right: Math.abs(model.x - snap.right),
    top: Math.abs(model.y - snap.top),
  };
  return {
    x: diff.left < diff.right ? snap.left : snap.right,
    y: diff.top < diff.bottom ? snap.top : snap.bottom,
  };
};

const SIBLING_X = uuidV4();
const SIBLING_Y = uuidV4();
const GUIDE_THRESHOLD = 5;
export const toGuides = ({
  threshold: {
    x: thresholdX = GUIDE_THRESHOLD,
    y: thresholdY = GUIDE_THRESHOLD,
  } = {
    x: GUIDE_THRESHOLD,
    y: GUIDE_THRESHOLD,
  },
}): Mops.SnapHandler => (
  // @ts-ignore
  { position, size },
  {
    // @ts-ignore
    guideRequests, guides, showGuides, hideGuides, removeGuides,
  },
  model = position,
) => {
  const tX = Math.max(GUIDE_THRESHOLD, thresholdX);
  const tY = Math.max(GUIDE_THRESHOLD, thresholdY);
  const withGuides = guideRequests.map(({ uuid, x, y }) => {
    const xMin = x - tX;
    const xMax = x + tX;
    const yMin = y - tY;
    const yMax = y + tY;
    const xBounds = [
      position.x,
      position.x + size.width / 2,
      position.x - size.width / 2,
    ];
    const yBounds = [
      position.y,
      position.y + size.height / 2,
      position.y - size.height / 2,
    ];
    let snapXPosition = NaN;
    let snapX = false;
    for (const posX of xBounds) {
      snapX = inRange(posX, xMin, xMax);
      if (snapX) {
        const diff = posX - position.x;
        snapXPosition = x - diff;
        break;
      }
    }
    let snapYPosition = NaN;
    let snapY = false;
    for (const posY of yBounds) {
      snapY = inRange(posY, yMin, yMax);
      if (snapY) {
        const diff = posY - position.y;
        snapYPosition = y - diff;
        break;
      }
    }
    const snap: Partial<Mops.GuideRequest> = {
      uuid,
      x: snapX ? snapXPosition : undefined,
      y: snapY ? snapYPosition : undefined,
    };
    return snap;
  });

  // @ts-ignore
  const siblingGuideX = guides.filter((guide) => guide.siblingGuide !== undefined && guide.siblingGuide === 'x');
  // @ts-ignore
  const siblingGuideY = guides.filter((guide) => guide.siblingGuide !== undefined && guide.siblingGuide === 'y');

  const withSnap = withGuides.reduce((previousValue, { uuid, x, y }) => {
    const snap = previousValue;
    if (typeof x === 'number'
      && (siblingGuideY.length === 0
        || (siblingGuideX.length > 0 && Math.abs(position.x - siblingGuideX[0].x1) > Math.abs(position.x - x)))) {
      snap.x = x;
      uuid && showGuides([uuid]);
      removeGuides([SIBLING_X]);
    } else if (typeof y === 'number'
      && (siblingGuideY.length === 0
        || (siblingGuideY.length > 0 && Math.abs(position.y - siblingGuideY[0].y1) > Math.abs(position.y - y)))) {
      snap.y = y;
      uuid && showGuides([uuid]);
      removeGuides([SIBLING_Y]);
    } else {
      uuid && hideGuides([uuid]);
    }
    return snap;
  }, {});
  return { ...model, ...withSnap };
};

export const toSiblings = (siblings: Mops.Sibling[]): Mops.SnapHandler => (
  {
    position,
    size,
    // @ts-ignore
    rotation,
    // @ts-ignore
    offset,
  },
  {
    addGuides,
    removeGuides,
    updateGuide,
    guides,
  },
  model = position,
  boundingBox = {
    position,
    size,
    rotation,
  },
) => {
  const withBoundingBox = siblings.map(sibling => ({
    ...sibling,
    boundingBox: getBoundingBox({
      angle: sibling.rotation.z,
      height: sibling.size.height,
      width: sibling.size.width,
    }),
  }));

  const initialValue: {
    x: {
      uuid?: string,
      value?: number,
      offset?: number,
    },
    y: {
      uuid?: string,
      value?: number,
      offset?: number,
    };
  } = {
    x: {},
    y: {},
  };
  const modelXs = [
    boundingBox.position.x - boundingBox.size.width / 2,
    boundingBox.position.x,
    boundingBox.position.x + boundingBox.size.width / 2,
  ];
  const modelYs = [
    boundingBox.position.y - boundingBox.size.height / 2,
    boundingBox.position.y,
    boundingBox.position.y + boundingBox.size.height / 2,
  ];
  const withSnap = withBoundingBox
    .map(
      (
        {
          uuid,
          ...item
        },
      ) => {
        const itemXs = [
          item.position.x - item.size.width / 2,
          item.position.x,
          item.position.x + item.size.width / 2,
        ];
        let itemSnapX = NaN;
        let modelSnapX = NaN;
        let closestXDistance = Infinity;
        modelXs.forEach(modelX => {
          itemXs.forEach(itemX => {
            const distance = Math.abs(itemX - modelX);
            if (distance < GUIDE_THRESHOLD && distance < closestXDistance) {
              itemSnapX = itemX;
              modelSnapX = modelX;
              closestXDistance = distance;
            }
          });
        });

        const itemYs = [
          item.position.y - item.size.height / 2,
          item.position.y,
          item.position.y + item.size.height / 2,
        ];
        let itemSnapY = NaN;
        let modelSnapY = NaN;
        let closestYDistance = Infinity;
        modelYs.forEach(modelY => {
          itemYs.forEach(itemY => {
            const distance = Math.abs(itemY - modelY);
            if (distance < GUIDE_THRESHOLD && distance < closestYDistance) {
              itemSnapY = itemY;
              modelSnapY = modelY;
              closestYDistance = distance;
            }
          });
        });

        return {
          uuid,
          xOffset: modelSnapX - model.x,
          x: !Number.isNaN(itemSnapX)
            ? itemSnapX
            : undefined,
          yOffset: modelSnapY - model.y,
          y: !Number.isNaN(itemSnapY)
            ? itemSnapY
            : undefined,
        };
      },
    )
    .reduce(
      (
        previousValue: {
          x: {
            uuid?: string,
            value?: number,
            offset?: number
          },
          y: {
            uuid?: string,
            value?: number,
            offset?: number
          }
        },
        {
          uuid,
          xOffset,
          x,
          yOffset,
          y,
        },
      ) => {
        const hadX = typeof previousValue.x.value === 'number';
        const hadY = typeof previousValue.y.value === 'number';
        const hasX = typeof x === 'number';
        const hasY = typeof y === 'number';
        const smallerX = hasX && hadX
          ? (
            // @ts-ignore
            Math.abs((previousValue.x.value + previousValue.x.offset) - model.x) > Math.abs((x + xOffset) - model.x)
          )
          : true;
        const smallerY = hasY && hadY
          ? (
            // @ts-ignore
            Math.abs((previousValue.y.value + previousValue.y.offset) - model.y) > Math.abs((y + yOffset) - model.y)
          )
          : true;
        return {
          x: {
            uuid: hasX && smallerX ? uuid : previousValue.x.uuid,
            value: hasX && smallerX ? x : previousValue.x.value,
            offset: hasX && smallerX ? xOffset : previousValue.x.offset,
          },
          y: {
            uuid: hasY && smallerY ? uuid : previousValue.y.uuid,
            value: hasY && smallerY ? y : previousValue.y.value,
            offset: hasY && smallerY ? yOffset : previousValue.y.offset,
          },
        };
      },
      initialValue,
    );
  const hasSnap = {
    x: typeof withSnap.x.value === 'number',
    y: typeof withSnap.y.value === 'number',
  };
  const snaplings = {
    x: hasSnap.x ? withBoundingBox.find(({ uuid }) => uuid === withSnap.x.uuid) : undefined,
    y: hasSnap.y ? withBoundingBox.find(({ uuid }) => uuid === withSnap.y.uuid) : undefined,
  };
  if (hasSnap.x) {
    // @ts-ignore
    const dir = snaplings.x.position.y > model.y ? -1 : 1;
    // @ts-ignore
    const snaplingGuideY1 = snaplings.x.position.y - (snaplings.x.size.height / 2);
    // @ts-ignore
    const snaplingGuideY2 = snaplings.x.position.y + (snaplings.x.size.height / 2);
    // @ts-ignore
    let modelGuideY = hasSnap.y ? (withSnap.y.value - withSnap.y.offset) : position.y;
    if (withSnap.x.offset !== 0) {
      // @ts-ignore
      modelGuideY += (size.height / 2) * dir;
      // @ts-ignore
    }
    const [y1, y2] = [
      // @ts-ignore
      Math.min(modelGuideY, snaplingGuideY1),
      // @ts-ignore
      Math.max(modelGuideY, snaplingGuideY2),
    ];
    const guide = {
      uuid: SIBLING_X,
      visible: true,
      // @ts-ignore
      x1: withSnap.x.value + snaplings.x.offset.x,
      // @ts-ignore
      x2: withSnap.x.value + snaplings.x.offset.x,
      // @ts-ignore
      y1: y1 + snaplings.x.offset.y,
      // @ts-ignore
      y2: y2 + snaplings.x.offset.y,
    } as Mops.Guide;
    if (guides.find(({ uuid }) => uuid === SIBLING_X)) {
      updateGuide(guide);
    } else {
      addGuides([guide]);
    }
  } else {
    removeGuides([SIBLING_X]);
  }
  if (hasSnap.y) {
    // @ts-ignore
    const dir = snaplings.y.position.x > model.x ? -1 : 1;
    // @ts-ignore
    const snaplingGuideX1 = snaplings.y.position.x - (snaplings.y.size.width / 2);
    // @ts-ignore
    const snaplingGuideX2 = snaplings.y.position.x + (snaplings.y.size.width / 2);
    // @ts-ignore
    let modelGuideX = hasSnap.x ? (withSnap.x.value - withSnap.x.offset) : position.x;
    if (withSnap.y.offset !== 0) {
      // @ts-ignore
      modelGuideX += (size.width / 2) * dir;
      // @ts-ignore
    }
    const [x1, x2] = [
      // @ts-ignore
      Math.min(modelGuideX, snaplingGuideX1),
      // @ts-ignore
      Math.max(modelGuideX, snaplingGuideX2),
    ];
    const guide = {
      uuid: SIBLING_Y,
      visible: true,
      // @ts-ignore
      x1: x1 + snaplings.y.offset.x,
      // @ts-ignore
      x2: x2 + snaplings.y.offset.x,
      // @ts-ignore
      y1: withSnap.y.value + snaplings.y.offset.y,
      // @ts-ignore
      y2: withSnap.y.value + snaplings.y.offset.y,
    } as Mops.Guide;
    if (guides.find(({ uuid }) => uuid === SIBLING_Y)) {
      updateGuide(guide);
    } else {
      addGuides([guide]);
    }
  } else {
    removeGuides([SIBLING_Y]);
  }

  const snap: Partial<Mops.PositionModel> = {
    // @ts-ignore
    x: hasSnap.x ? (withSnap.x.value - withSnap.x.offset) : model.x,
    // @ts-ignore
    y: hasSnap.y ? (withSnap.y.value - withSnap.y.offset) : model.y,
  };

  return snap;
};
